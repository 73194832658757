import React, { Component } from "react";

class English extends Component {
  render() {
    return (
      <center>
        <center>
          <h1>English</h1>
        </center>
        <br />
        <div className="info">
          <p style={{ maxWidth: "500px" }}>
            Folkmusikkaféet at Allégården in Göteborg, Sweden is a folk music
            scene that is open on Friday evenings during autumn, winter, and
            spring. On concert nights there is an entrance fee. On play and
            dance nights entrance is free and if you play dance music you can
            write your name on the play list and play. If you have questions
            regarding booking, please contact{" "}
            <a href="mailto:artistbokare@folkmusikkafeet.net">
              <img
                src="img/ikoner/epost-12.png"
                className="email"
                alt="e-post:"
              />{" "}
              artistbokare@folkmusikkafeet.net
            </a>
            <br />
            <br />
            For any other questions please contact{" "}
            <a href="mailto:folkmusikkafeet@folkmusikkafeet.net">
              <img
                src="img/ikoner/epost-12.png"
                className="email"
                alt="e-post:"
              />{" "}
              folkmusikkafeet@folkmusikkafeet.net
            </a>
            <br />
            <br />
          </p>

          <table className="defList">
            <tbody>
              <tr>
                <td>Opening hours:</td>
                <td>Fridays from 1900&ndash;2330</td>
              </tr>
              <tr>
                <td>Address:</td>
                <td>
                  Folkmusikkaféet
                  <br />
                  Allégården
                  <br />
                  Södra Allégatan 4<br />
                  413 01 Göteborg
                  <br />
                  SWEDEN
                  <br />
                  <a href="http://kartor.eniro.se/m/9CNAa">Eniro</a>
                  <a href="http://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Folkmusikkaf%C3%A9et&sll=57.699634,11.957209&sspn=0.009551,0.033023&ie=UTF8&t=h&z=16&iwloc=A">
                    Google
                  </a>
                </td>
              </tr>
              <tr>
                <td>GPS coordinates:</td>
                <td>
                  Lat N 57&deg; 41&rsquo; 58&rdquo; Lon E 11&deg; 57&rsquo;
                  25&rdquo;
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <img
            src="/img/folkmusikkafeet.jpg"
            className="rundad"
            width="70%"
            maxWidth="700px"
          />
          <br />
          <i>
            A sold out evening with Väsen. Magic happening, you don't want to
            miss these nights!
          </i>
          <br />
          <br />
          <img
            src="/img/fmk_exterior.jpg"
            className="rundad"
            width="70%"
            maxWidth="700px"
          />
          <br />
          <i>This is the right place</i>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </center>
    );
  }
}

export default English;
