import React, { Component } from "react";
import Concert from "./concert";
import "./concert.css";

import ScrollIndicator from "./ScrollIndicator.jsx";

/* Here is what is displayed on top, first of all */
const openingSection = (
  <div className="program-heading">
    <center>
      <h1>Varmt välkomna till Folkmusikkaféet</h1>
      Vi är en mötesplats för levande folkmusik och dans i Göteborg.
      <br />
      Folkmusik och dans varje fredag.
      <br />
      <br />
      <small>
        Bli medlem och få rabatt på inträdet. Du bidrar till att vi finns!
        <br />
        Du blir medlem i entrén på en konsertkväll
        <br /> eller genom att sätta in 50 kr på PG 107741-1, alt. Swish
        1236279434. <br />
        Ange namn, adress, telefonnummer och e-postadress.
        <br />
        Medlemskap gäller per kalenderår.
        <br />
        <br />
        Konsertbiljetter köpes i entrén. Ingen förbokning.
        <br />
        Spel och danskvällar, heldanskväll och spelmansstämma har Fri entré.
        <br />
      </small>
    </center>
  </div>
);

/* Here the program is inserted! */
const theProgram = [
  <Concert
    title="Spel- och danskväll"
    date="2025-01-17"
    imgMaxWidth="95%"
    cost={0} // Frivillig entréavgift
    img="img/program/vt2025/sod_17jan.jpg"
  >
    <div className="pBody">
      <center>
        Folkmusikkaféet inleder vårsäsongen med en spel- och danskväll – dans
        till spellista. Två grupper är bokade att inleda kvällen, sedan är det
        fritt fram att skriva upp sig på listan.
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Spelmanslaget (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Tillsammansgruppen</td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Ingela och Håkan Lindberg lär ut grundpolska. Spelman: Björn
                Johnsson.
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Spelstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Sunniva Abelli lär ut låtar från Västerbotten.</small>
            </td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Hisingens spelmanslag och Triol inleder, sedan följer öppen
                spellista.
              </small>
            </td>
          </tr>
        </table>
        <br />
        <small>Kafévärd denna afton: Hisingens Spelmanslag</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title=<div>
      <strike>JAERV</strike> - inställd konsert
    </div>
    date="2025-01-24"
    imgMaxWidth="50%"
    cost={180}
    costYoung={95}
    img="img/program/vt2025/JAERV.jpg"
    imgLink="https://www.jaerv.com/"
    canceled
  >
    <div className="pBody">
      <center>
        I genuin spelglädje skapar Jaerv sina virvlande melodier med såväl
        instrument som sina fem röster där den varma, breda vokalklangen ger dem
        en särprägel i svensk folkmusik. Sedan starten i Göteborg för snart 20
        år sedan har de flitigt turnerat världen över, släppt ett flertal album,
        fått prestigefyllda priser och samarbetat med både stora folkmusiker,
        körer och symfoniorkestrar. Äntligen är det Folkmusikkaféets tur att få
        njuta av deras kärleksfulla samspel.
        <br />
        <br />
        <b>
          OBS: Konserten med Jaerv måste tyvärr ställas in pga influensa/covid i
          bandet. Billetobiljetterna återbetalas.
        </b>
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Spelmanslaget (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Danskurs för nybörjare</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Första gången (se <a href="/kurser">kursinfo</a>)
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Visstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Annelie Westerlund lär ut visor från Västergötland.</small>
            </td>
          </tr>
          <tr>
            <td>20:00-21:00</td>
            <td>Konsert: JAERV [INSTÄLLD]</td>
          </tr>
          <tr>
            <td>21:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                <strike>JAERV</strike>
              </small>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>I pausen spelar Annelie Westerlund</small>
            </td>
          </tr>
        </table>
        <br />
        <small>Kafévärd denna afton: Näverluren</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title=<div>
      Stordanskväll
      <br />
      Emma Reid &amp; Ellika Frisell samt Mia Marine
    </div>
    date="2025-01-31"
    imgMaxWidth="95%"
    cost={160}
    costYoung={85}
    img="img/program/vt2025/EmmaEllika.jpg"
    imgTwo="img/program/vt2025/MiaMarine.jpg"
  >
    <div className="pBody">
      <center>
        På en stordanskväll är det dans hela kvällen – ingen konsert!
        <br />
        <br />
        Emma &amp; Ellika: Två mästerliga musiker i ett samspel som kokar av
        driv och färgrikedom. Det är tätt, lyssnande och egensinnigt, alltid med
        svänget i centrum. Djupt förankrade i den gamla spelmanstraditionen
        spelar Emma och Ellika kraftfullt och variationsrikt låtar med
        improviserat stämspel. Nya kompositioner av både Emma och Ellika står
        sida vid sida med traditionsmaterial från Bingsjö och Orsa.
        <br />
        <br />
        Mia Marine är välkänd för Göteborgspubliken, som många gånger fått dansa
        till hennes svängiga fiolspel. Det brukar bli blandade danser, med
        tonvikt på Värmland, Dalarna och Norge. Ett av hennes signum är dock att
        hon spelar polskor från många olika håll, med stor genrekunskap,
        självklart danssväng och en smittande spelglädje.
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Danskurs för nybörjare</td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Peter Nordqvist och Alice Nordqvist lär ut rørospols. Spelmän:
                Ingela och Håkan Lindberg.
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Visstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Ann-Britt Lindhult lär ut några visor i väntan på solen.
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Emma Reid &amp; Ellika Frisell samt Mia Marine.</small>
            </td>
          </tr>
        </table>
        <small>Kafévärd denna afton: FMK:s styrelse + medarbetare</small>
      </center>
    </div>
  </Concert>,
  <Concert
    title="Floating Sofa Quartet – Jubileumskonsert"
    date="2025-02-07"
    imgMaxWidth="95%"
    cost={180}
    costYoung={95}
    img="img/program/vt2025/FloatingSofaQ.jpg"
    imgLink="http://floatingsofaquartet.com/"
  >
    <div className="pBody">
      <center>
        Dansk/Finsk/Svenska Floating Sofa Quartet har i 10 år förenat nordiska
        kulturer i musik som bevarar och förnyar traditioner. Med 300+
        konserter, 5 album och priser som en dansk grammis 2018, skapar de ett
        unikt sound av dansmusik och kammarmusik. Rotade i folkmusik kombinerar
        de traditionella melodier med nykomponerad musik.
        <br />
        <br />
        Clara Tesch [DK], fiol; Leija Lautamaja [FI], durspel, tramporgel; Mads
        Kjøller-Henningsen [DK], tvärflöjt, svensk säckpipa; Malte Zeberg [SE],
        kontrabas.
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Danskurs för nybörjare</td>
          </tr>
          <tr>
            <td>20:00-21:00</td>
            <td>Konsert: Floating Sofa Quartet</td>
          </tr>
          <tr>
            <td>21:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Floating Sofa Quartet</small>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>I pausen spelar Dannito</small>
            </td>
          </tr>
        </table>
        <small>Kafévärd denna afton: Polskedanslaget</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title="Fru Skagerrak"
    date="2025-02-14"
    imgMaxWidth="95%"
    cost={160}
    costYoung={85}
    img="img/program/vt2025/FruSkagerrak.jpg"
    imgLink="https://fruskagerrak.com/"
  >
    <div className="pBody">
      <center>
        Fru Skagerraks musik tar dig med på en resa genom Skandinavien; från
        lågland till bergen, från långsamma melodier till brusande polskor. Fru
        Skagerrak består av tre musiker – Maja Kjær Jacobsen från Danmark, Elise
        Wessel Hildrum från Norge och Anna Lindblad från Sverige. Trion
        sammanför olika traditioner från vart och ett av deras hemland: en
        repertoar av traditionella låtar, nya kompositioner och sånger.
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Danskurs för nybörjare</td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Ingela och Håkan Lindberg lär ut vals. Spelman: Björn Johnsson.
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Spelstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Hans Grönlund lär ut låtar från Värmland och Västerdalarna.
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-20:50</td>
            <td>Konsert: Fru Skagerrak</td>
          </tr>
          <tr>
            <td>20:50-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Fru Skagerrak</small>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>I pausen spelar Borås spelmanslag</small>
            </td>
          </tr>
        </table>
        <small>Kafévärd denna afton: Folk Youth VG</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title="Lars-Olof Ejstes &amp; Marika Jakobsson"
    date="2025-02-21"
    imgMaxWidth="95%"
    cost={160}
    costYoung={85}
    img="img/program/vt2025/EjstesJakobsson.jpg"
    imgLink="https://www.ejstes.se/Skivor/default.htm"
  >
    <div className="pBody">
      <center>
        Musik för Dansen – Dansen för Musiken. Samspelet mellan Marika Jakobsson
        och Lars-Olof Ejstes har varat under 40 års tid. Största intresset i
        spelandet är tradition och spel till dans. Det bjuds på polskor av de
        flesta typer, och schottis, polka, vals och annat från gammeldansen.
        Lars-Olof har sina musikaliska rötter i Väster- och Österdalarna.
        Traditionerna ligger bl a till grund för egenskapade låtar som är stor
        del av repertoaren. Marika har sina rötter i Östra Skaraborg och i
        Lars-Olofs repertoar.
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Tillsammansgruppen</td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Danskurs för nybörjare</td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Visstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Robert Qvist lär ut visor från CD:n Kärleksbrev och ryska
                satelliter (2005).
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-20:50</td>
            <td>Konsert: Lars-Olof Ejstes &amp; Marika Jakobsson</td>
          </tr>
          <tr>
            <td>20:50-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Lars-Olof Ejstes &amp; Marika Jakobsson</small>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>I pausen spelar Bagaregårdens spelmanslag</small>
            </td>
          </tr>
        </table>
        <small>Kafévärd denna afton: Karin med vänner</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title="Rullstråk"
    date="2025-02-28"
    imgMaxWidth="95%"
    cost={160}
    costYoung={85}
    img="img/program/vt2025/Rullstrak.jpg"
  >
    <div className="pBody">
      <center>
        Robert Larsson, Erika Lindgren Liljenstolpe och Örjan Englund är tre av
        Upplands främsta spelmän som med fioler, dragspel, piano och sång
        förmedlar sin musik med stor skicklighet, värme och humor. I konserten
        varvar de låtar ur den uppländska folkmusikskatten med tongångar från
        Bingsjö i Dalarna, egna kompositioner, sånger och berättelser.
        Musikalisk tradition och förnyelse när det är som bäst!
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Danskurs för nybörjare</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Sista gången (se <a href="/kurser">kursinfo</a>).
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Peter Nordqvist och Marie Schoonderwaldt lär ut polska från
                Bingsjö med variationer. Spelmän: Eva Blanck och Jan Nilsson.
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Spelstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Lars-Gunnar Franzén lär ut låtar från Västerdalarna och
                Värmland.
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-20:50</td>
            <td>Konsert: Rullstråk</td>
          </tr>
          <tr>
            <td>20:50-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Rullstråk</small>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                I pausen leder Tommy Tannerud och Biörn Landahl långdans och
                balladdans.
              </small>
            </td>
          </tr>
        </table>
        <small>Kafévärd denna afton: Bagaregården</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title="Heldanskväll"
    date="2025-03-07"
    imgMaxWidth="95%"
    cost={0} // Frivillig entréavgift
    img="img/program/vt2025/Vastkustfolk.jpg"
    imgTwo="img/program/vt2025/Tagel&Co.jpg"
  >
    <div className="pBody">
      <center>
        En helkväll för alla dansare. Folkmusikkaféets spelmanslag, Tagel &amp;
        Co och Västkustfolk kommer att spela till dans två halvtimmar var. Vi
        kan räkna med att det blir mycket polskor men också några gammaldanser.
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:00</td>
            <td>Årsmöte</td>
          </tr>
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>FMK:s spelmanslag, Tagel &amp; Co och Västkustfolk.</small>
            </td>
          </tr>
        </table>
        <small>Kafévärd denna afton: Västkustfolk</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title=<div>
      Göteborgs Folk Festival:
      <br />
      Lena Jonsson Trio
    </div>
    date="2025-03-14"
    imgMaxWidth="95%"
    cost={180}
    costYoung={95}
    img="img/program/vt2025/LJ3.jpg"
    imgLink="https://lenajonsson.com/"
  >
    <div className="pBody">
      <center>
        <div style={{ color: "green" }}>
          <br />
          <a
            href="https://secure.tickster.com/sv/cu1vav723bng5d3"
            style={{ textDecoration: "underline" }}
          >
            KÖP BILJETTER TILL HELA FESTIVALEN 14-15 MARS HÄR
          </a>
          <br />
          <br />
          Den här kvällen är en del av Göteborg Folk Festival 14-15 Mars.
          <br />
          <br />
          14 Mars <b>här på Folkmusikkaféet</b>
          <br />
          15 Mars <b>Folkklubb på Musikens Hus</b>
          <br />
          <br />
          <i>
            Lördagen arrangeras i samarbete
            <br /> med Folk Youth VG
          </i>
          <br />
          <br />
        </div>
        Prisbelönta Lena Jonsson Trio balanserar mellan det virtuosa och det
        lekfullt musikaliska och rör sig sömlöst mellan de olika musikstilarna
        och världarna i Jonssons kompositioner. De väver samman Lenas kraftfulla
        fiolspel och Kristofers drivande bas med Eriks varierade och lyhörda
        gitarrklanger. Trion tar med lyssnaren på en resa med gripande,
        innerliga polskor, virtuosa bluegrasslåtar och allt däremellan. Trion
        blev i april 2023 utsedd till ”Årets Artist” på folk &amp;
        världsmusikgalan i Sverige och vann 2021 både Grammis och Manifestpriset
        samt årets album av Lira musikmagasin. I juni 2023 släpptes deras
        senaste album *Elements* som var nominerad till en Grammis.
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Fortsättningskurs i dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Första gången (se <a href="/kurser">kursinfo</a>).
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Spelstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Malin Klittvall lär ut låtar från Bohuslän.</small>
            </td>
          </tr>
          <tr>
            <td>20:00-21:00</td>
            <td>Konsert: Lena Jonsson Trio</td>
          </tr>
          <tr>
            <td>21:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Lena Jonsson Trio</small>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>I pausen spelar Fredy Clue med vänner.</small>
            </td>
          </tr>
        </table>
        <small>Kafévärd denna afton: Maud med vänner</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title="Haltli/Vårdal/Amper"
    date="2025-03-21"
    imgMaxWidth="95%"
    cost={180}
    costYoung={95}
    img="img/program/vt2025/HaltliVardalAmper.jpg"
  >
    <div className="pBody">
      <center>
        Tre gränsöverskridande traditionsbärare från den norska och svenska
        folkmusikscenen samlas i denna nya trio, där de rör sig från
        fascinerande kärlekssånger och hisnande improvisationer till att sätta
        eld på dansgolv i de vildaste Finnskogpolser! Frode Haltli på dragspel
        och Vegar Vårdal på fiol och hardingfela har spelat tillsammans sedan
        90-talet och är bägge trendsättare inom norsk folkmusik. Riksspelman
        Emilia Amper, nyckelharpa, tenornyckelharpa och sång, har spelat med
        bägge i andra grupperingar, men denna trio gjorde premiär 2022.
        <br />
        <br />
        Välkommen till en magisk värld med brilliant känslig och vilt svängig
        svensk-norsk traditionsmusik när den är som bäst!
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Fortsättningskurs i dans</td>
          </tr>
          <tr>
            <td>18:45-19:45</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Malte Hermansson och Kerstin Harvenberg lär ut finnskogspols.
                Spelmän: Karin Westerståhl och Lennart Ehn.
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Visstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Ingrid Westerfors lär ut visor om livet, kärleken och döden.
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-20:50</td>
            <td>Konsert: Haltli/Vårdal/Amper</td>
          </tr>
          <tr>
            <td>20:50-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Haltli/Vårdal/Amper</small>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>I pausen spelar Kristians kapell.</small>
            </td>
          </tr>
        </table>
        <small>Kafévärd denna afton: GNDS</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title="Spel- och danskväll"
    date="2025-03-28"
    imgMaxWidth="95%"
    cost={0} // Frivillig entréavgift
    img="img/program/vt2025/sod_28mar.jpg"
  >
    <div className="pBody">
      <center>
        På en spel- och danskväll är det dans till spellista. Vi har bokat
        spelmän som inleder kvällen, sedan är det fritt fram att skriva upp sig
        på listan.
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Tillsammansgruppen</td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Fortsättningskurs i dans</td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Fredrik Persson, Höstrusk och Juuli Kõrre inleder, sedan följer
                öppen spellista.
              </small>
            </td>
          </tr>
        </table>
        <small>Kafévärd denna afton: Skjortor och Särkar</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title="Västra Låtverkstan"
    date="2025-04-04"
    imgMaxWidth="95%"
    cost={160}
    costYoung={85}
    img="img/program/vt2025/vastra_latverkstan.jpg"
    imgLink="https://folkmusikivast.se/vastra-latverkstan/"
  >
    <div className="pBody">
      <center>
        Ungdomar från hela regionen bjuder på tretakt och rockiga riff i en
        medryckande blandning! Publiken får höra en svängig och inspirerande
        konsert med unga spelmän som framför folkmusik på nya och kreativa sätt,
        under ledning av Jenny Gustafsson och Anders Ådin.
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Fortsättningskurs i dans</td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Visstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Mikael Rittri lär ut visor om mat.</small>
            </td>
          </tr>
          <tr>
            <td>20:00-20:50</td>
            <td>Konsert: Västra Låtverkstan</td>
          </tr>
          <tr>
            <td>20:50-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Västra Låtverkstan, Jenny Gustafsson och Anders Ådin.
              </small>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>I pausen spelar MacRill.</small>
            </td>
          </tr>
        </table>
        <small>Kafévärd denna afton: FMK:s styrelse + medarbetare</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title=<div>
      Vågspel
      <br />
      <small>Releasekonsert</small>
    </div>
    date="2025-04-11"
    imgMaxWidth="95%"
    cost={160}
    costYoung={85}
    img="img/program/vt2025/vagspel.jpg"
    imgLink="https://www.vagspel.com/"
  >
    <div className="pBody">
      <center>
        Med rötterna stadigt i den västgötska låtskatten spelar Vågspel både
        egenskrivna låtar och traditionella. Med två fioler får duon musiken att
        fara som en ångvält genom landskapet där dåtid och nutid möts. Alice
        Klint och Kristin Kennemark spelar uppkäftigt och lyriskt på samma gång,
        med ett samspel och sväng som kan sätta vad som helst i rörelse.
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:15</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td>18:00-19:30</td>
            <td>Fortsättningskurs i dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Sista gången (se <a href="/kurser">kursinfo</a>).
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Dansstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Petrix Hessel och Andreas Lindström lär ut slängpolska.
                <br />
                Spelman: Fredy Samuel Lundh.
              </small>
            </td>
          </tr>
          <tr>
            <td>18:30-19:30</td>
            <td>Spelstuga</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                Svenska vänföreningen folkmusik i Kurdistan lär ut låtar från
                Kurdistan.
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-20:50</td>
            <td>Konsert: Vågspel</td>
          </tr>
          <tr>
            <td>20:50-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>Vågspel</small>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>I pausen spelar Kråkstråk.</small>
            </td>
          </tr>
        </table>
        <small>Kafévärd denna afton: Polskedansklaget</small>
      </center>
    </div>
  </Concert>,

  <Concert
    title="Spelmansstämma"
    date="2025-04-25"
    imgMaxWidth="95%"
    cost={0} // Frivillig entréavgift
    img="img/program/vt2025/spelmansstamma.jpg"
  >
    <div className="pBody">
      <center>
        Välkommen på spelmansstämma, vårens final på Folkmusikkaféet! Allspel,
        buskspel och spel till dans.
        <br />
        <br />
        <table className="program-tabell">
          <tr>
            <td>17:30-19:00</td>
            <td>Spelkurs</td>
          </tr>
          <tr>
            <td>19:00-20:00</td>
            <td>Allspel i stora salen</td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Spel till dans</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>
                I stora salen: FMK:s spelmanslag, Lei-la, Tageltrång och Mams
                &amp; Paps inleder.
              </small>
            </td>
          </tr>
          <tr>
            <td>20:00-23:00</td>
            <td>Buskspel</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <small>I resten av huset.</small>
            </td>
          </tr>
        </table>
        <small>Kafévärd denna afton: Mams och Paps</small>
      </center>
    </div>
  </Concert>,
];

const FadeInSection = ({ children }) => {
  const domRef = React.useRef();

  const [isVisible, setVisible] = React.useState(false);

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      // In your case there's only one element to observe:
      if (entries[0].isIntersecting) {
        // Not possible to set it back to false like this:
        setVisible(true);

        // No need to keep observing:
        observer.unobserve(domRef.current);
      }
    });

    observer.observe(domRef.current);

    if (React.isValidElement(domRef.current)) {
      return () => observer.unobserve(domRef.current);
    }
  }, []);

  return (
    <section
      ref={domRef}
      className={isVisible ? "entrySec is-visible" : "entrySec"}
    >
      {children}
    </section>
  );
};

class Program extends Component {
  styles = {
    fontSize: 15,
    fontWeight: "bold",
  };

  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      latest: null,
    };
    this.sortArchive();
  }

  getProgram() {
    return theProgram;
  }

  sortArchive() {
    theProgram.sort((a, b) => {
      let dateDiff = a.props.date.localeCompare(b.props.date);
      if (dateDiff < 0) {
        return -1;
      } else if (dateDiff > 0) {
        return 1;
      }
      return 0;
    });
  }

  render() {
    let theProgramCopy = [...theProgram];
    let scrollIndicator = <ScrollIndicator />;

    while (theProgramCopy.length > 0) {
      let p = new Concert(theProgramCopy[0].props);
      if (!p.dateOK()) {
        theProgramCopy.shift();
      } else {
        break;
      }
    }

    if (theProgramCopy.length == 0) {
      return (
        <div>
          {openingSection}
          <center>
            <h1>Tack för denna säsong!</h1>
            Hoppas vi ses även nästa. Vi kommer snart att presentera kommande
            program.
            <br />
            <br />
            <a href="/arkiv">Vårt arkiv över tidigare kvällar som varit</a>
            <br />
            <br />
          </center>
        </div>
      );
    }

    if (theProgramCopy.length > 1) {
      let prog = [...theProgramCopy];
      let firstShow = prog.shift();
      /* All except the first one should have this swooch effect */
      let items = prog.map((concert) => (
        <FadeInSection key={concert}>{concert}</FadeInSection>
      ));
      items.unshift(<div className="is-visible">{firstShow}</div>);
      return (
        <div>
          {scrollIndicator}
          {openingSection}
          {items}
          <br />
          <center>
            <a
              style={{
                "font-size": "30px",
                border: "1px solid black",
                padding: "10px",
                "border-radius": "5px",
              }}
              href="/arkiv"
            >
              Tidigare program
            </a>
          </center>
          <br />
          <br />
          <br />
        </div>
      );
    }
    return (
      <div>
        {openingSection}
        {theProgram}
        <center>
          <a
            style={{
              "font-size": "30px",
              border: "1px solid black",
              padding: "10px",
              "border-radius": "5px",
            }}
            href="/arkiv"
          >
            Tidigare program
          </a>
        </center>
        <br />
        <br />
      </div>
    );
  }
}

export default Program;
